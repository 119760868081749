<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="text-h5"> ¿Desea cerrar sesión? </v-card-title>
      <v-card-text></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="confirm()">
          Cerrar sesión
        </v-btn>
        <v-btn color="green darken-1" text @click="cancel()"> Cancelar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogConfirmLogout",
  props: ["open"],
  data() {
    return {
      dialog: this.open,
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm-logout");
    },
    cancel() {
      this.$emit("cancel-logout");
    },
  },
  watch: {
    open(value) {
      this.dialog = value;
    },
  },
};
</script>
